#countdown {
  position: relative;
  width: 250px;
  padding: 10px;
  display: inline-block;
  text-align: center;
  box-shadow: 0px 0px 70px 1px rgb(255 255 255 / 40%);
  -webkit-box-shadow: 0px 0px 70px 1px rgb(255 255 255 / 40%);
  -moz-box-shadow: 0px 0px 70px 1px rgb(255 255 255 / 40%);
  border-radius: 70px;
  -webkit-border-radius: 70px;
  -moz-border-radius: 70px;
  -ms-border-radius: 70px;
  -o-border-radius: 70px;
  background: rgb(155 155 155 / 10%); /* Fondo semi-transparente */
  backdrop-filter: blur(10px); /* Efecto de desenfoque en el fondo */
}
#countdown .box {
  padding: 10px;
  border-right: solid 1px rgba(255, 255, 255, 0.2);
}
#countdown .col-4:last-child .box {
  border-right-color: transparent;
}
#countdown .box p {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}
#countdown .box .text {
  font-size: 12px;
  font-family: sans-serif;
}

@media (min-width: 768px) {
  .container {
    width: 1100px;
  }
  h1 {
    font-size: 58px;
  }
  #countdown {
    width: 350px;
    padding: 20px;
  }
}
