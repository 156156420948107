/* .redes {
  margin-top: 8%;
} */
.redes p {
  font-size: 14px;
  font-weight: 100;
}
.redes button {
  color: #fff;
  padding: 6px 22px;
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  background-color: rgba(255, 255, 255, 0.1);
  border: solid 2px transparent;
  cursor: pointer;
}
.redes button:hover {
  border-color: rgba(255, 255, 255, 0.3);
}

#modal {
  position: fixed;
  top: 100px;
  left: 30%;
  width: 500px;
  background: #fff;
  transition: opacity 1s;
  box-shadow: 0 7px 2px #444;
  z-index: 99999;
  opacity: 0;
}

.redes .social_profiles {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.redes .social_profile_item a {
  color: #000;
  font-size: 25px;
  display: flex;
}

.redes .social_profile_item {
  border-radius: 50%;
  background-color: rgb(239, 245, 249);
  margin: 5px;
  width: 46px;
  height: 46px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
}

.redes .social_profile_item:hover {
  background-color: rgba(47, 128, 237, 0.8);
}

.redes .social_profile_item:hover a{
  color: #fff;
}
