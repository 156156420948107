@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");

*,
:after,
:before {
  box-sizing: inherit;
}

body {
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  background-image: url(assets/img/bg.webp);
  background-position: top;
  background-size: cover;
  margin: 0;
}

@media (max-width: 768px) {
  body {
    background-size: auto;
    background-repeat: no-repeat;
    background-position: left;
  }
}

.App {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: rgb(49, 49, 177);
  background: linear-gradient(
    0deg,
    rgb(9 9 41) 0%,
    rgb(10 10 143) 50%,
    rgba(49, 49, 177, 0.6) 100%
  );
}

.logo {
  width: 100%;
  padding: 0;
  margin: 0;
}

.logo-body {
  width: 15%;
}

/* para dispositivos movil */
@media (max-width: 768px) {
  .logo-body {
    width: 35%;
  }
}

.container {
  width: 100%;
  /* margin: 15% auto; */
}
.col-4 {
  width: 25%;
  float: left;
}

h1 {
  font-size: 38px;
  text-transform: uppercase;
  line-height: 1;
}

@media (min-width: 768px) {
  /* .container {
    width: 1100px;
  } */
  h1 {
    font-size: 45px;
  }
}

.p-relative {
  position: relative;
  min-height: 100vh;
}

section {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.overlay img:nth-child(1) {
  position: absolute;
  top: 0px;
  left: 0%;
}
.overlay img:nth-child(2) {
  position: absolute;
  top: 0%;
  right: 0px;
}
.overlay img:nth-child(3) {
  position: absolute;
  bottom: 0%;
  left: 0px;
}
.overlay img:nth-child(4) {
  position: absolute;
  bottom: 0%;
  right: 0px;
}

.overlay img {
  max-width: 100%;
  padding: 0;
  margin: 0 0 1.45rem;
}

.overlay img {
  margin-bottom: 0px;
  width: 15%;
}

.animate {
  animation-fill-mode: both;
  animation-duration: 1000ms;
  animation-delay: 0ms;
  animation-iteration-count: 1;
  opacity: 1;
  animation-name: zoom;
}

@keyframes zoom {
  0% {
    opacity: 0;
    transform: translate3d(-100px, 0, 0) scale3d(0.5, 0.5, 1);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
}

.content-body {
  position: relative;
  height: 100%;
  /* min-height: 100vh; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content-header {
  display: flex;
  justify-content: center;
}
